<template>
    <div class="container">
        <button v-for="loja in lojas" :key="loja.loja" class="container-function" @click="goTo(loja)">
            <div class="function">
                <h3>{{ loja.nome }}</h3>
            </div>
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lojas: '',
        };
    },

    created() {
        this.lojas = JSON.parse(sessionStorage.getItem('promotores'));
    },

    methods: {
        goTo(loja) {
            sessionStorage.setItem('loja', loja.loja);
            sessionStorage.setItem('nomeLoja', loja.nome);
            sessionStorage.setItem('redeLoja', loja.rede);

            this.$router.push({ name: 'carregamento' });
        },
    },
};
</script>

<style scoped>
.container {
    margin: 40px;
    display: flex;
    flex-direction: column;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1.5rem;
    min-height: 20rem;
}

.container-function {
    border: none;
}

.function {
    display: flex;
    position: relative;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border: 1px solid var(--azul-escuro);
    border-radius: 10px;
    background-color: var(--verde);
    padding: 20px 100px;
    max-width: 300px;
    gap: 10px;
    box-shadow: -6px 7px 0px 0px var(--azul-escuro);
}

.function:hover {
    background-color: var(--azul-escuro);
    border: 1px solid var(--verde);
    box-shadow: -6px 7px 0px 0px var(--verde);
    position: relative;
    top: 5px;
}

.function h3 {
    font-size: 0.8rem;
    overflow: hidden;
    max-width: 30ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 289px) {
    .container {
        column-gap: 0;
    }
    .function {
        min-height: 5rem;
        max-height: 5rem;
        min-width: 5rem;
        max-width: 5rem;
    }

    .function h3 {
        font-size: 0.8rem;
    }
}
</style>
